<template>
  <nav-header :title="title"></nav-header>
  <div class="container">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <h4>Om spontantællinger</h4>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <h4>Formålet med spontantællingerne</h4>
            Spontantællingerne har til formål at indsamle data om de danske
            fuglebestande og skal anvendes i forbindelse med beskyttelse af
            Danmarks fugle og natur og i videnskabelige sammenhænge. Ligesom
            punkttællingerne fokuserer spontantællingerne på almindelige
            fuglearter med hovedfokus på art og antal. Hvor punkttællingerne
            bruges til at belyse bestandsudviklingen fra år til år, anvender vi
            spontantællingerne, fx når vi ønsker at vide, hvordan
            bestandsudviklingen er for en enkelt art i løbet af året, eller
            hvordan det her og nu står til med fuglelivet i en bestemt naturtype
            eller landsdel. <br /><br />
            <span>
              <h4>Din indsats</h4>
              Du kan deltage i spontantælling hvor og hvornår, du vil. En
              tælling finder sted på et punkt, foretages af én observatør, og
              varer 5 minutter, hverken mere eller mindre. Det er således enkelt
              og overkommeligt for den enkelte deltager, men tilsammen skaber
              tællingerne enestående viden om det danske fuglelivs aktuelle
              bruger af DOFbasen. Er du ikke det, kan du oprette dig
              <strong
                ><a target="_blank" href="https://dofbasen.dk/opretmig.php"
                  >her</a
                ></strong
              >
            </span>
            <br /><br />
            <span>
              <h4>Krav til fuglekendskab</h4>
              Spontantællinger kræver godt kendskab til landets fugle (dog ikke
              nødvendigvis de sjældne). Kravet til fuglekendskab er således lidt
              højere end det grundlæggende kendskab, der kræves til f.eks.
              punkttællinger (<strong
                ><a
                  target="_blank"
                  href="https://www.dof.dk/fakta-om-fugle/punkttaellingsprogrammet"
                  >læs mere her</a
                ></strong
              >). Det skyldes groft sagt, at enhver spontantælling sammenlignes
              med mange andre spontantællinger udført af andre tællere, mens
              punkttællinger sammenlignes med tidligere punkttællinger udført af
              samme deltager. Man behøver ikke have deltaget i
              punkttællingsprojektet for at blive spontantæller. Alle med et
              godt fuglekendskab opfordres til at deltage.
            </span>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <h4>Praktisk udførelse af spontantællinger</h4>
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <h4>Valg af tællepunkt</h4>
            <p>
              Du må udføre spontantælling hvor og hvornår, du vil, men vi vil
              gerne have foretaget tællinger over hele landet, så vi opfordrer
              til, at man opsøger områder med ingen eller få tællinger. Du kan
              få overblik over, hvor der er få tællinger på det seneste ved at
              besøge
              <a href="https://www.dofbasen.dk/point/index.php?map=danmark"
                >dæknings-/pointkortet</a
              >.
            </p>
            <p>
              Vi ønsker tællingerne foretaget så bredt som muligt, så de
              repræsenterer hele Danmark, alle naturtyper, hele året og hele
              døgnet. Vi er eksempelvis meget interesseret i punkter i
              agerlandet, da det er landets mest udbredte landskabstype, men
              også i mere fåtallige naturtyper som f.eks. heder, der er en
              underrapporteret landskabstype, hvad fugle angår. Naturforholdene
              på punktet skal dog IKKE beskrives af tælleren.
            </p>
            <p>
              Du kan udføre en spontantælling med eller uden kikkert, men undgå
              steder med meget begrænset udsyn. Punktet må dog gerne ligge i
              f.eks. by eller skov, selvom udsynet kan være begrænset her. Du
              kan som sagt altid tælle hvor og hvornår, du vil, men tællepunkter
              i rene naturtyper giver bedre muligheder for dataanalyse og
              mindsker desuden risikoen for, at man dækker flere
              DOFbaselokaliteter under spontantællingen.
            </p>
            <h4>Hvilke fugle skal tælles?</h4>
            <p>
              Alle de fugle, der ses eller høres fra optællingspunktet, og som
              kan artsbestemmes, inden for ca. 1 km fra tællepunktet efter din
              bedste vurdering. Du må IKKE bruge teleskop. Du må ikke påbegynde
              en spontantælling foranlediget af en allerede opdaget fugl, f.eks.
              en sjælden art. Dette for at undgå, at usædvanlige arter bliver
              overrepræsenteret. Hvis det ikke er muligt at artsbestemme eller
              notere samtlige fugle du ser inden de fem minutter er gået, er det
              tilladt at fortsætte indtastning efter tiden er gået. Sørg så vidt
              muligt for kun at indtaste fugle, der blev registreret inden for
              de fem minutter. Husk, du også gerne må tælle alle pattedyr,
              padder, krybdyr og sommerfugle.
            </p>
            <h4>Om brugen af diverse bestemmelsesapps, fx Merlin</h4>
            <p>
              Du må ikke bruge apps som fx Merlin eller BirdNet, da disse apps
              alt for ofte bestemmer forkert. Hvis du synes, du har et stort
              behov for en sådan app, kan du fx i stedet foretage dine
              spontantællinger et sted i dit lokalområde, hvor du er mere
              fortrolig med fuglestemmerne.
            </p>
            <h4>Hvornår på døgnet kan spontantællinger foretages?</h4>
            <p>
              Spontantællinger kan foretages hele døgnet. Fuglene er som regel
              mest aktive først på dagen, men du kan frit vælge at tælle på det
              tidspunkt, der passer dig bedst.
            </p>
            <h4>Vejrforhold</h4>
            <p>
              Spontantællinger bør kun udføres i godt vejr. Regn og tæt tåge bør
              undgås. Vejrforholdene kan indtastes, men det er ikke obligatorisk
              at gøre det.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            <h4>Indtastning af spontantællinger</h4>
          </button>
        </h2>
        <div
          id="collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <b>Indtastning:</b> Sker bedst ved brug af den særlige
            spontantællings-app, som findes på
            <a href="https://spontan.dofbasen.dk">spontan.dofbasen.dk</a>. Fra
            hjemmeside kan appen installeres på telefonen, så den også kan
            bruges offline. <br />Du kan lave indtastning direkte i felten via
            ny tælling” eller indtaste tællinger efterfølgende via ”Indtast
            gennemført tælling”. <br /><br />
            Ved live-indtastning vælges dato og starttid automatisk af app’en.
            Placeringen angives via telefonens GPS-signal eller ved at brugeren
            selv placerer ’nålen’. Nålen skal sættes der, hvorfra brugeren står
            stille og laver sin tælling.
            <br /><br />
            Efter endt tælling vælger appen selv den mest præcise
            DOFbaselokalitet. <br />Hvis der er mangler lokalitetsgrænser i
            området, eller der er begrænset forbindelse til internettet, vælger
            appen selv lokaliteten med den nærmeste centerkoordinat. Der er
            siden mulighed for at ændre den valgte lokalitet <br /><br />
            Det er muligt, men ikke nødvendigt, at indtaste detaildata såsom køn
            og alder. Disse detaildata må ikke tage fokus fra grunddata, dvs.
            art, antal og primær adfærd. Nye individer af samme art, der
            registreres i løbet af de 5 minutter, tilføjes blot til det allerede
            indtastede antal for arten.
            <br /><br />
            Hvis en spontantælling ikke indtastes i appen under tællingen, men i
            stedet fx noteres i en notesbog, bør tællingen indtastes i appen
            snarest muligt efter tællingen. I så fald indtastes datoen og
            starttiden (sluttiden sættes automatisk til 5 min senere).
            <br /><br />
            <b>Varigheden af en spontantælling:</b> 5 minutter, hverken mere
            eller mindre. Appen til spontantælling indeholder et stopur, der
            sikrer den korrekte varighed. Ved indtastning aktiveres der på
            skærmen en tidstælleknap, der starter med at tælle ned nogle
            sekunder, og som derefter holder styr på de 5 minutter. Efter 5
            minutter starter alarmen, men uret fortsætter med at tælle ned,
            altså i minus. HUSK at slå lyd/vibration til på mobiltelefonen!
            Appen skal være åben og mobilen ulåst for at alarmen fungerer.
            <br /><br />
            En tælling kan ikke afbrydes og derefter genoptages; hvis tællingen
            afbrydes, er den gået tabt og må slettes. Men start meget gerne
            forfra, det tager kun 5 minutter!
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            <h4>Spiltilstand</h4>
          </button>
        </h2>
        <div
          id="collapseFive"
          class="accordion-collapse collapse"
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Spil-elementet i appen skal gøre det sjovere for brugeren at besøge
            områder, der normalt ikke tælles fugle i og belønne denne indsats!
            <br />Spiltilstand kan når som helst slås til/fra under menupunktet
            indstillinger. <br /><br />
            Beregningen af antal point for en tælling tager udgangspunkt i antal
            tællinger i kvadratet i den seneste måned. Point gives kun for en
            tælling højst én gang i timen i samme kvadrat, og efter den første
            sådanne tælling gives der kun 10 point for øvrige tællinger samme
            dag i samme kvadrat for hver bruger. Der er desuden en tidsgrænse på
            14 dage for hvor længe efter en tælling, man må være om at indtaste
            den, hvis man vil have points for den. Du kan se dine point og din
            placering under menupunktet ’Min Statistik’ på forsiden.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSix"
            aria-expanded="false"
            aria-controls="collapseSix"
          >
            <h4>Spørgsmål og kontakt</h4>
          </button>
        </h2>
        <div
          id="collapseSix"
          class="accordion-collapse collapse"
          aria-labelledby="headingSix"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Vil du vide mere om spontantællingerne, kan du læse mere i DOF’s
            publikationer og på DOF’s hjemmeside
            <a href="https://www.dof.dk/fakta-om-fugle/spontantaellinger"
              >www.DOF.dk/fakta-om-fugle/spontantællinger</a
            >. <br />Du er altid velkommen til at kontakte de projektansvarlige
            i Fuglenes Hus: <br /><br />Thomas Vikstrøm (<a
              href="mailto: thomas.vikstroem@dof.dk"
              >thomas.vikstroem@dof.dk</a
            >) & Daniel Palm Eskildsen (<a href="mailto: dpe@dof.dk"
              >dpe@dof.dk</a
            >) <br />Fuglenes Hus <br />Vesterbrogade 140 <br />1620 København
            V. <br />Tlf.: 3328 3800 (kl. 11-15)

            <br /><br />© Dansk Ornitologisk Forening 2021
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</template>

<script>
import NavHeader from "@/components/navbars/NavHeader.vue";

export default {
  components: {
    NavHeader,
  },
  data() {
    return {
      title: this.$englishPreferred ? "Guide" : "Vejledning",
    };
  },
};
</script>

<style scoped>
.accordion-button {
  color: #fff !important;
  background: #1f7aaf;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
</style>
